import React, {Component} from 'react';
import HeroAvicom from './Hero/HeroAvicom';
import FeaturesAvicom from './Features/FeaturesAvicom';
import ServiceAvicom from './Services/ServiceAvicom';
import ContentBlockAvicom from './ContentBlockAvicom';
import ContactAvicom from './Contact/ContactAvicom';
import TeamAvicom from './TeamAvicom';

class HomeAvicom extends Component {

    render() {
        return (
            <React.Fragment>
                <HeroAvicom BigTitle="Find your best domain name and create a Site" SubTitle="Up to 50% Off Domain and Hosting, Starting from $ 2.50/Month." />
                <FeaturesAvicom/>
                <ServiceAvicom/>
                <ContentBlockAvicom/>
                <TeamAvicom/>
                <ContactAvicom/>
            </React.Fragment>

        )
    }
}

export default HomeAvicom
