import React, {Component} from 'react'
import SectionTitle from './SectionTitle'


class TeamAvicom extends Component {
  render() {

    var data = [{
      id: 1,
      name: "Katja Siilinen",
      position: "UI/UX Designer",
      imgUrl: "1"
    },
      {
        id: 2,
        name: "Aleksandr Viktorov",
        position: "CEO and Founder",
        imgUrl: "2"
      },
      {
        id: 3,
        name: "Juho Salminen",
        position: "CTO",
        imgUrl: "3"
      },
      {
        id: 4,
        name: "Katerina Tarazevich",
        position: "Copywriter",
        imgUrl: "4"
      }
    ];

    return (
      <section className="out-tem-wrap section-padding pt-0">
        <div className="container">

          <div className="row">
            <div className="col-lg-8 col-md-10 col-12 offset-lg-2 offset-md-1">
              <div className="section-title text-center">
                <span/>
                <h2>Meet Avicom team</h2>
                <p>"None of us, including me, ever do great things. But we can all do small things, with great love, and
                  together we can do something wonderful"</p>
                <p className="text-right">&mdash; Mother Teresa</p>
              </div>
            </div>
          </div>
          <div className="row">
            {
              data.map(member => {

                return (
                  <div className="col-md-6 col-xl-3 col-sm-12 col-12" key={member.id}>
                    <div className={`single-team-member text-center ${member.id === 2 ? 'active' : '' }`}>
                      <div className="member">
                        <div className="member-img">
                          <img src={require(`../assets/img/avicom-team-photo/${member.imgUrl}.jpg`)} alt="Avicom {member.position}"/>
                        </div>
                        <div className="member-info">
                          <h4>{member.name}</h4>
                          <span>{member.position}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
    )
  }
}

export default TeamAvicom
