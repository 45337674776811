import React, {Component} from 'react'
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import {Link as PageLink} from 'react-router-dom';
import {Link} from "react-scroll";
import $ from 'jquery';

export class NavbarAvicom extends Component {

  componentDidMount() {

    $(window).scroll(function () {
      var Width = $(document).width();

      if ($("body").scrollTop() > 100 || $("html").scrollTop() > 100) {
        if (Width > 767) {
          $("header").addClass("sticky");
        }
      } else {
        $("header").removeClass("sticky");
      }
    });


  }


  render() {

    return (
      <header className="header-wraper transparent-menu">
        <div className="main-menu">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-10 d-flex">
                <PageLink className="navbar-brand logo" to="/">
                  <img src={require("../assets/img/logo.svg")} alt="Avicom"/>
                  <h1>avicom</h1>
                </PageLink>
              </div>
              <div className="col-md-8 col-2 text-lg-right">
                {/*<Navbar expand="lg" className="menu-1">
                  <Navbar.Toggle aria-controls="basic-navbar-nav">
                    <span className="fal fa-bars"/>
                  </Navbar.Toggle>
                  <Navbar.Collapse id="basic-navbar-nav" className="menu-items">
                    <Nav className="mr-auto">
                      <NavDropdown title="Pages" id="basic-nav-dropdown">
                        <Nav.Item>
                          <PageLink to="/">Home Avicom</PageLink>
                        </Nav.Item>
                        <Nav.Item>
                          <PageLink to="/HomeOne">Home 1</PageLink>
                        </Nav.Item>
                        <Nav.Item>
                          <PageLink to="/HomeTwo">Home 2</PageLink>
                        </Nav.Item>
                        <Nav.Item>
                          <PageLink to="/HomeThree">Home 3</PageLink>
                        </Nav.Item>
                      </NavDropdown>
                      <Nav.Item>
                        <Link className="nav-link" activeClass="active" to="feature" spy={true} smooth={true}
                              offset={-70}
                              duration={500}> Features
                        </Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link className="nav-link" activeClass="active" to="price" spy={true} smooth={true} offset={-70}
                              duration={500}> Pricing
                        </Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link className="nav-link" activeClass="active" to="service" spy={true} smooth={true}
                              offset={-70}
                              duration={500}>Services</Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link className="nav-link" activeClass="active" to="faq" spy={true} smooth={true} offset={-70}
                              duration={500}>
                          FAQ
                        </Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link className="nav-link" activeClass="active" to="testimonial" spy={true} smooth={true}
                              offset={-70}
                              duration={500}> Testimonial
                        </Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link className="nav-link" activeClass="active" to="contact" spy={true} smooth={true}
                              offset={-70}
                              duration={500}> Contact
                        </Link>
                      </Nav.Item>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>*/}
                <Navbar expand="lg" className="menu-1">
                  <Navbar.Toggle aria-controls="basic-navbar-nav">
                    <span className="fal fa-bars"/>
                  </Navbar.Toggle>
                  <Navbar.Collapse id="basic-navbar-nav" className="menu-items">
                    <Nav.Item>
                      <a href="tel:+358401563900" className="nav-link"><i className="fal fa-headphones-alt" /> &nbsp; +358401563900</a>
                    </Nav.Item>
                    <Nav.Item>
                      <a href="tel:contact@avicom.fi" className="nav-link"><i className="fal fa-envelope-open-text" /> &nbsp; contact@avicom.fi</a>
                    </Nav.Item>
                  </Navbar.Collapse>
                </Navbar>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default NavbarAvicom
