import React, {Component} from 'react'

class FeaturesAvicom extends Component {

  render() {

    var data = [
      {
        id: 1,
        title: "Web-development",
        text: "We offer comprehensive website creation to drive traffic and engage visitors with insightful UX and UI design.",
        image: require(`../../assets/img/features-icons/web-development.svg`)
      },
      {
        id: 2,
        title: "Mobile development",
        text: "Let us build your conceptualized mobile app or reconfigure your existing site for user-friendly mobile viewing and functionality.",
        image: require(`../../assets/img/features-icons/mobile-development.svg`)
      },
      {
        id: 3,
        title: "Software development",
        text: "We offer full-cycle custom software development from engineering to testing, quality assurance, system integration and support.",
        image: require(`../../assets/img/features-icons/software-development.svg`)
      },
      {
        id: 4,
        title: "IT-consulting",
        text: "Difficulties with your IT projects? Trust our experienced IT consultants to guide your digital strategy and uncover technical roadblocks.",
        image: require(`../../assets/img/features-icons/it-consulting.svg`)
      }
    ];

    var {classAdd} = this.props;

    return (
      <section className={`featured-domain-offer  ${classAdd === "yes" ? 'feature-v3 section-padding' : '' }`}
               id="feature">
        <div className="container" style={{'margin': '120px auto -200px'}}>
          <div className="row">
            {
              data.map(item => {
                return (
                  <div className="col-lg-3 col-sm-6 col-12 d-flex" key={item.id}>
                    <div className="single-domain-name text-center">
                      <img className="pb-2" src={item.image} alt="klaud"/>
                      <h3 style={{"min-height": '50px'}}>{item.title}</h3>
                      <p className="mt-2">{item.text}</p>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
    )
  }
}

export default FeaturesAvicom
