import React, {Component} from 'react'
import SectionTitle from './SectionTitle'


class ContentBlockAvicom extends Component {
  render() {
    return (
      <section className="content-block-wrap section-padding pb-130">
        <div className="container">

          <SectionTitle Title="What we can offer"
                        Titlep="Our company knows how to digitalize your business efficiently using modern technology."/>

          <div className="row">
            <div className="col-xl-5 col-lg-6  col-sm-12 col-12">
              <div className="content-block" data-aos="fade-right" data-aos-duration={1200}>
                <span className="top-title">Avicom</span>
                <h2>Hauska tutustua!*</h2>
                <p>Avicom is a Finnish technology company that creates digital solutions. The full potential of
                  technology is underutilized in everyday life. We are making the most of the digital era to offer new
                  ways of managing standardized tasks.</p>
                <ul>
                  <li>5+ years of experience in IT</li>
                  <li>24/7 Online Customer Support</li>
                  <li>High-level specs who passionate about what they do</li>
                  <li className="li-asterisk">Nice to meet you!</li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 offset-xl-1 col-sm-12 col-12">
              <div className="content-featured-img" data-aos="fade-left" data-aos-duration={1200}>
                <img src={require("../assets/img/content/avicom-team.png")} alt="Avicom"/>
              </div>
            </div>
          </div>
          <div className="row flex-row-reverse">
            <div className="col-xl-5 col-lg-6 offset-xl-1 col-sm-12 col-12">
              <div className="content-block pt-60" data-aos="fade-left" data-aos-duration={1200}>
                <span className="top-title">ESL</span>
                <h2>Electronic shelf labels</h2>
                <p>Digital solution for retail, management, exhibitions and manufactures. Simply display product
                  information, promotions and discount in one click. Easy-to-use management by special mobile app.</p>
                <p>More cost-efficient than old paper labels and eco-friendly way to restructure your business.</p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12 col-12">
              <div className="content-featured-img pt-60" data-aos="fade-right" data-aos-duration={1200}>
                <img src={require("../assets/img/content/esl.png")} alt="Electronic shelf labels"/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-sm-12 col-12">
              <div className="content-block pt-60" data-aos="fade-up" data-aos-duration={1200}>
                <span className="top-title">ChatBot</span>
                <h2>Smart chat center for high-quality customer and cost optimization support</h2>
                <p>For small businesses, we provides a cloud platform for creating smart bots in instant messengers. For medium and large businesses we offer a team of experts implementing solutions based on artificial intelligence.
Your communication with clients in all instant messengers will be easy and quick. Like a chat with your friends.</p>
                <ul>
                  <li>Processing thousands of applications 24/7/365</li>
                  <li>Replacing first-line support operators</li>
                  <li>Systematization of the feedback collection and accounting</li>
                  <li>Unified level of service in all channels</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6  offset-xl-1 col-sm-12 col-12">
              <div className="content-featured-img pt-60" data-aos="fade-up" data-aos-duration={1200}>
                <img src={require("../assets/img/content/chatbot.png")} alt="chatbot"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default ContentBlockAvicom
