import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({text}) => <div style={{
  'border-radius': '6px',
  'border': '2px solid white',
  'background': '#3a2975',
  'padding': '4px',
  'width': '143px'
}}>{text}</div>;

export class Gmap extends Component {

  static defaultProps = {
    center: {
      lat: 60.807759,
      lng: 21.402729
    },
    zoom: 11
  };


  render() {
    return (


      <div style={{height: '280px', width: '100%'}}>
        <GoogleMapReact
          bootstrapURLKeys={{key: 'AIzaSyCAtfNiYnBLBEuXK2JZvskX4W7wWOt2bUw'}}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={60.807759}
            lng={21.402729}
            text="Metsärunolantie 39, 23500 Uusikaupunki"
          />
        </GoogleMapReact>
      </div>


    )
  }
}

export default Gmap
