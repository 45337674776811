import React, { Component } from 'react'
import NavbarAvicom from './NavbarAvicom';

class HeaderAvicom extends Component {
    render() {
        return (
            <NavbarAvicom />
        )
    }
}

export default HeaderAvicom
