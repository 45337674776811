import React, {Component} from 'react'
import SectionTitle from '../SectionTitle'


class ServiceThree extends Component {
  render() {

    var data = [
      {
        id: 1,
        title: "Digitality",
        text: "Today many common business practices can be made digital, giving you greater control over the sales process.",
        imgUrl: "digitality.svg"
      },
      {
        id: 2,
        title: "Modern technologies",
        text: "Advanced technology is a necessity of modern life. The more accessible your information is, the closer you can connect to your customers.",
        imgUrl: "modern-technologies.svg"
      },
      {
        id: 3,
        title: "Innovations",
        text: "Innovation puts you one step ahead of the competition. We can help restructure your business model to align with the latest market trends.",
        imgUrl: "innovations.svg"
      },
      {
        id: 4,
        title: "IT-infrastructure",
        text: "Whether you need a cloud based or traditional network solution, we can help manage or transform your IT infrastructure.",
        imgUrl: "it-infrastructure.svg"
      },
      {
        id: 5,
        title: "Chatbots",
        text: "Artificial intelligence will take over the processing of the most common call-center requests, as well as collecting and systematization of feedback. Delegate your routine to a robot.",
        imgUrl: "chatbot.svg"
      },
      {
        id: 6,
        title: "Electronic shelf labels",
        text: "ESL is simple and modern decision for your business in today's digital era. Optimise price update process, eliminate errors and simplify the work.",
        imgUrl: "esl.svg"
      }
    ];


    return (
      <section className="services-wrap service-three section-padding" id="service">
        <div className="container">

          <SectionTitle Title="Maximize your competitive advantage"
                        Titlep="Make your life easier and business more efficient and profitable by using latest features of technology."/>

          <div className="row">
            {
              data.map(item => {
                return (
                    <div className="col-lg-4 col-md-6 col-12 d-flex" key={item.id}>
                      <div className="service-box-2 servicev3" data-aos="zoom-in" data-aos-duration={1000}>
                        <div className="service-icon">
                          <img src={require(`../../assets/img/services-icons/${item.imgUrl}`)} alt={item.title}/>
                        </div>
                        <h4>{item.title}</h4>
                        <p>{item.text}</p>
                      </div>
                    </div>
                )
              })
            }
          </div>
        </div>
      </section>
    )
  }
}

export default ServiceThree
